<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column">
      <div
          class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text my-rounds-header-container">
        <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)"
             class="go-back-key">
        {{ formatString('my_rounds_primary_header_text') }}
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text edit-predictions-header">
        {{ formatString('my_rounds_secondary_header_text') }}
      </div>
    </div>
    <EnteredRoundBar v-for="(activeLeagueEdit, index) in this.roundsToEdit" :key="index"
                     :activeLeagueEdit="activeLeagueEdit"/>
    <div class="d-flex flex-column justify-content-center align-items-center text-center pt-4"
         v-if="noRoundsEnteredContainer">
      <img class="col-6" src="../assets/game/red-card-icon.png">
      <div @click="goToPlay()" class="col-10 pt-2">{{ formatString('my_rounds_no_predictions_text') }}
        <strong>{{ formatString('my_rounds_no_predictions_text_bold') }}</strong></div>
    </div>
    <div class="published-rounds-header pt-4 p-2">
      {{ formatString('my_rounds_published_rounds_header_text') }}
    </div>
    <PublishedRoundBar v-for="(leagueRound, index) in this.publishedRounds" :key="index" :leagueRound="leagueRound"/>
  </div>
</template>

<script>
import PredictorService from "@/services/predictorService";
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import EnteredRoundBar from "@/components/EnteredRoundBar";
import PublishedRoundBar from "@/components/PublishedRoundBar";

export default {
  name: "MyRounds",
  components: {EnteredRoundBar, PublishedRoundBar},
  data() {
    return {
      roundsToEdit: [],
      publishedRounds: [],
      noRoundsEnteredContainer: false,
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    backButtonClicked() {
      this.$router.push({name: 'LandingPage'});
    },
    async getEnteredRounds() {
      const upcomingSports = (await PredictorService.getActiveSports(`${this.user.msisdn}`, `${this.user.username}`)).data;
      this.checkNoRounds(upcomingSports);
    },
    checkNoRounds(upcomingSports) {
      if (upcomingSports.error === true) {
        this.noRoundsEnteredContainer = true
      } else {
        this.roundsToEdit = upcomingSports.filter(function (sport) {
          return sport.HasPredictions === true && sport.Promo === false && sport.Free === false;
        })
      }
    },
    goToPlay() {
      this.$router.push({name: 'SportSelection'});
    },
    async getPublishedRounds() {
      const getSports = PredictorService.getAllSports();
      this.publishedRounds = getSports.data
    }
  },
  async beforeMount() {
    console.log('test')
    this.setIsLoading(true);
    await this.getEnteredRounds();
    await this.getPublishedRounds();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: cover;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.my-rounds-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.edit-predictions-header {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.published-rounds-header {
  font-weight: 600;
}

</style>
