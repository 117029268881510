<template>
  <div class="body-container" v-if="!this.isLoading">
    <div
        class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text league-selection-header-container">
      <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)"
           class="go-back-key">
      {{ formatString('round_selection_header_text', [this.todayDate]) }}
    </div>
    <div
        class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text select-league-container">
      {{ formatString('round_selection_select_sport_text') }}
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center pt-5"
         v-if="allRoundsEnteredContainer">
      <img class="col-6 boots-icon-img" src="../assets/game/boots-icon.png">
      <div @click="goToMyRounds()" class="col-11 pt-2">{{ formatString('round_selection_entered_all_rounds_text') }}
        <strong>{{ formatString('round_selection_entered_all_rounds_text_bold') }} </strong></div>
    </div>
    <SportBar class="pt-2" v-for="(activeSport, index) in this.activeSports" :key="index" :activeSport="activeSport"/>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import SportBar from "@/components/SportBar";
import {translationsFormatter} from "@/functions/translationsFormatter";
import moment from "moment";
import PredictorService from "@/services/predictorService";

export default {
  name: "SportSelection",
  components: {SportBar},
  data() {
    return {
      todayDate: moment().format('D MMMM'),
      allRoundsEnteredContainer: false,
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getActiveSports();
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'activeSports', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser', 'setActiveSports', 'setSelectedSport']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    async getActiveSports() {
      const upcomingSports = (await PredictorService.getActiveSports(`${this.user.msisdn}`, `${this.user.username}`)).data;
      if (upcomingSports.error === true) {
        this.allRoundsEnteredContainer = true
      } else {
        this.setUpcomingSports(upcomingSports);
      }
    },
    setUpcomingSports(upcomingSports) {
      let filteredSport = upcomingSports.filter(function (sport) {
        return sport.HasPredictions === false && sport.Promo === false && sport.Free === false;
      });
      const sports = filteredSport.reduce((sports, item) => {
        const sport = (sports[item.SportName] || []);
        sport.push(item);
        sports[item.SportName] = sport;
        return sports;
      }, {});
      this.setSelectedSport(sports)
      this.setActiveSports(sports)
    },
    backButtonClicked() {
      this.$router.push('/profile');
    },
    goToMyRounds() {
      this.$router.push('/my-rounds');
    }
  },
}
</script>

<style scoped>

.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: cover;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.league-selection-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.select-league-container {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.boots-icon-img {
}


</style>
